<template>
  <div>
    <b-card>
      <b-row>
        <b-col sm="12">
          <b-tabs align="left">
            <b-tab active title="Grid">
              <grid />
            </b-tab>

            <b-tab title="Maps" no-body>
              <maps />
            </b-tab>
          </b-tabs>
        </b-col>
        
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BTabs, BTab, BCard, BCol, BRow, BButton } from "bootstrap-vue";
import Maps from "./maps.vue";
import Grid from "./grid.vue";

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BCard,
    BCol,
    BRow,
    maps: Maps,
    grid: Grid,
  },
};
</script>