<template>
  <div>
    <b-card-body class="w-100 h-100">
      <div class="table-responsive text-nowrap">
      <b-table
        striped
        hover
        responsive
        :items="table_items"
        :fields="table_fields"
        small
        table-variant="success"
        t
      >
        <!-- template cell tanggal -->
        <template v-for="field in date_fields" v-slot:[`cell(${field})`]="data">
          <div :key="field">
            <feather-icon
              icon="CheckIcon"
              style="color: blue"
              v-if="data.value"
            />
            <feather-icon icon="XIcon" style="color: red" v-else />
          </div>
        </template>
      </b-table>
      </div>
      <b-button
                variant="success"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1 ml-1"
                size="sm"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                Download
              </b-button>
    </b-card-body>
  </div>
</template>

<script>
import { BTable, BButton, } from "bootstrap-vue";

export default {
  components: {
    BTable, BButton,
  },

  data() {
    return {
      date_fields: [
        // untuk template cell tanggal
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      table_items: [
        {
          id: 96001,
          nama_stasiun: "Stasiun Metereologi Malkussaleh",
          "01": true,
          "02": false,
          "03": true,
          total: 30,
        },
        {
          id: 96002,
          nama_stasiun: "Stasiun Metereologi Bandung",
          "01": true,
          "02": true,
          "03": true,
          total: 30,
        },
        {
          id: 96003,
          nama_stasiun: "Stasiun Metereologi Jakarta",
          "01": false,
          "02": true,
          "03": true,
          "07": true,
          "15": true,
          total: 30,
        },
      ],
      table_fields: [
        "id",
        "nama_stasiun",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "total",
      ],
    };
  },
};
</script>
