<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <b-card>
          <b-row>
            <b-col sm="2">
              <b-form-group label="Resource" label-for="select-station" size="sm" inline id="select-form">
                <b-form-select v-model="selectedForm" id="select-station" size="sm">
                  <option :value="option">-Pilih-</option>
                  <option value="a"> Petir</option>
                  <option value="b"> Magnet</option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="Region" label-for="select-wmo" size="sm">
                <b-form-select v-model="t_wmoid" class="" id="select-wmo" size="sm">
                  <option :value="option">-Pilih-</option>
                  <option value="a">Region I</option>
                  <option value="b">Region II</option>
                  <option value="c">Region III</option>
                  <option value="d">Region IV</option>
                  <option value="e">Region V</option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="Provinsi" label-for="select-station" size="sm">
                <b-form-select v-model="t_station" id="select-station" size="sm">
                  <option :value="option">-Pilih-</option>
                  <option value="a"> Nanggroe Aceh Darussalam</option>
                  <option value="b"> Sumatera Utara</option>
                  <option value="c"> Sumatera Selatan</option>
                  <option value="d"> Sumatera Barat</option>
                  <option value="e"> Bengkulu</option>
                  <option value="f"> Riau</option>
                  <option value="g"> Kepulauan Riau </option>
                  <option value="h"> Jambi</option>
                  <option value="i"> Lampung</option>
                  <option value="j"> Bangka Belitung</option>
                  <option value="k"> Kalimantan Barat</option>
                  <option value="l"> Kalimantan Timur</option>
                  <option value="m"> Kalimantan Selatan</option>
                  <option value="n"> Kalimantan Tengah</option>
                  <option value="o"> Kalimantan Utara</option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="Kabupaten" label-for="select-station" size="sm">
                <b-form-select v-model="t_station" id="select-station" size="sm">
                  <option :value="option">Pilih</option>
                  <option value="1">Kabupaten 1</option>
                  <option value="2">Kabupaten 1</option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="Date" label-for="select-date" size="sm">
                <b-form-datepicker id="datepicker-dateformat2" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="en" size="sm" placeholder="Date" />
              </b-form-group>
            </b-col>

            <b-col sm="2 mt-2 pad2">
              <b-form-group>
                <b-form inline id="select-form">
                  <b-form-select class="col-4 mr-1 pad2" size="sm">
                    <option :value="option">Jam</option>
                    <option value="01">00</option>
                    <option value="02">01</option>
                    <option value="03">02</option>
                    <option value="04">03</option>
                    <option value="05">04</option>
                    <option value="06">05</option>
                    <option value="07">06</option>
                    <option value="08">07</option>
                    <option value="09">08</option>
                    <option value="10">09</option>
                    <option value="11">10</option>
                    <option value="12">11</option>
                    <option value="13">12</option>
                    <option value="14">13</option>
                    <option value="15">14</option>
                    <option value="16">15</option>
                    <option value="17">16</option>
                    <option value="18">17</option>
                    <option value="19">18</option>
                    <option value="20">19</option>
                    <option value="21">20</option>
                    <option value="22">21</option>
                    <option value="23">22</option>
                    <option value="24">23</option>
                  </b-form-select>
                  <!-- <b-button v-on:click="onFetch" variant="primary" class="ml-sm-6" size="sm">Filter</b-button> -->
                  <b-button @click="onFetch" variant="primary" class="ml-sm-6" size="sm">Filter</b-button>
                </b-form>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>

    <petir v-if="choosenForm == 'a'"> </petir>
    <magnet v-if="choosenForm == 'b'"> </magnet>
  </div>
</template>

<script>
import { BCard, BCardHeader, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BFormGroup, BCollapse, BFormDatepicker, BFormInput } from "bootstrap-vue";
import Swal from 'sweetalert2'
import Petir from "./geomonitoringcomponent/petir.vue";
import Magnet from "./geomonitoringcomponent/magnet.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    BFormInput,
    Petir,
    Magnet,
    Swal,
    // Thermohigograph,
  },
  data: function() {
    return {
      backgroundLoading: "#ff8000",
      selectedForm: "",
      choosenForm: "",
      t_station: "",
      t_wmoid: "",
      t_date: "",
      option: "",
    };
  },

  methods: {
    onFetch() {
      Swal.fire({
        title: 'Do you want to save the changes?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Save',
        denyButtonText: `Don't save`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.fire('Saved!', '', 'success')
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info')
        }
      })
    
      this.choosenForm = this.selectedForm;
    },
  },
  openLoadingBackground() {
    // this.$vs.loading({ background: this.backgroundLoading, color: "#ff8000" });
    // setTimeout(() => {
    //   this.$vs.loading.close();
    // }, 5000);
  },
    
};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
</style>
